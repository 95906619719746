<template>
  <div class="p-1">
    <div class="mb-2 flex flex-wrap justify-between">
      <a-button @click="$router.back()">
        Regresar
      </a-button>
    </div>
    <template v-if="pensum">
      <div class="flex items-end justify-between flex-wrap my-4 pb-4">
        <h3 class="text-2xl">
          {{ pensum.name }}
        </h3>
        <div class="flex space-x-2">
          <a-dropdown title="Ver opciones">
            <template #menu>
              <a-dropdown-item :to="{ name: 'pensums.edit', params: { id: pensum.id } }" v-if="user.isAdmin">
                Actualizar
              </a-dropdown-item>
              <a-dropdown-item @click="deletePensum({ id: pensum.id, back: true })" v-if="user.isAdmin">
                Eliminar
              </a-dropdown-item>
              <template v-if="pensumPerdios.length >= 1">
                <hr>
                <p class="px-2 mt-1 text-gray-500">Opciones de impresión</p>
                <a-dropdown-item
                  v-for="period in pensumPerdios"
                  :href="`${url}/api/pensums/${$route.params.id}/pdf?period_id=${period.id}&token=${token}`" target="_blank"
                  :key="period.id">
                  Periodo {{ period.year }}-{{ period.number }}
                </a-dropdown-item>
              </template>
            </template>
          </a-dropdown>
        </div>
      </div>
      <div class="rounded-2xl bg-white border pb-4">
        <div class="flex flex-wrap mb-2 px-4 pt-4">
          <div class="w-full md:w-1/5 lg:w-1/5">
            <b style="color: #4D4F5C">Descripción</b>
          </div>
          <div class="w-full md:w-1/1 lg:w-1/2">
            {{ pensum.description }}
          </div>
        </div>

        <div class="flex flex-wrap mb-2 px-4">
          <div class="w-full md:w-1/5 lg:w-1/5">
            <b style="color: #4D4F5C">Especialidad</b>
          </div>
          <div class="w-full md:w-1/1 lg:w-1/2">
            {{ pensum.specialization.title }}
          </div>
        </div>

        <div class="flex flex-wrap">
          <table v-for="semester in semestersData" :key="semester.id" class="border-t w-full">
            <thead>
            <tr class="border-b">
              <th class="p-2 bg-gray-100 text-left" width="20%">Semestre: {{ semester.number }}</th>
              <th class="p-2 bg-gray-100 text-left" width="40%">Materias</th>
              <th class="p-2 bg-gray-100 text-center" width="15%">Créditos</th>
              <th class="p-2 bg-gray-100 text-center" width="15%">Entrega final</th>
              <th class="p-2 pr-4 bg-gray-100 text-center" width="15%">Opciones</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="course in semester.enabled_courses" :key="course.id">
              <td class="border-b p-2">{{ course.code }}</td>
              <td class="border-b p-2">{{ course.title }}</td>
              <td class="border-b p-2 text-center">{{ course.credits }}</td>
              <td class="border-b p-2 text-center">{{ course.pivot.final_delivery ? 'Si' : 'No' }}</td>
              <td class="border-b p-2 text-center">
                <a-dropdown>
                  <template #menu>
                    <a-dropdown-item @click="finalDeliveryModal(semester, course)">
                      Entrega final
                    </a-dropdown-item>
                  </template>
                </a-dropdown>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <a-modal
      title="Opciones"
      :show="finalEvaluationsModal"
      @confirm="confirm()"
      @cancel="finalEvaluationsModal = false">

      <div class="space-y-4">
        <label class="mb-2 block font-medium text-gray-700" for="calculated_avg">¿Entrega final?</label>
        <a-switch class="mb-4" id="calculated_avg" v-model="form.final_delivery" />
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import { URL } from '@/constants';
const token = localStorage.getItem(process.env.VUE_APP_USER_TOKEN_NAME)
export default {
  data: () => ({
    token,
    url: URL,
    periods: [],
    finalEvaluationsModal: false,
    form: {
      final_delivery: false,
    },
    selectedSemester: {},
    selectedCourse: {}
  }),
  computed: {
    ...mapState({
      user: ({ session }) => session.user,
      pensum: state => state.pensums.pensum,
      semestersData: state => state.pensums.pensum.semesters.sort((a, b) => a.number - b.number),
      pensums: state => state.pensums.pagination.data.sort((a, b) => a.number > b.number ? 1 : -1)
    }),
    pensumPerdios() {
      return this.pensum?.periods ?? []
    }
  },
  methods: {
    ...mapActions({
      fetchPensum: 'pensums/show',
      deletePensum: 'pensums/delete'
    }),
    ...mapMutations({
      setPensum: 'pensums/SET_PENSUM',
      setNavbarTitle: 'SET_NAVBAR_TITLE'
    }),
    finalDeliveryModal(semester, course) {
      this.finalEvaluationsModal = true
      this.form.final_delivery = course.pivot.final_delivery
      this.selectedSemester = semester
      this.selectedCourse = course
    },
    confirm () {
      // const { semester_id, course_id } = this.pensum
      // console.log(this.selectedSemester, this.selectedCourse, this.form)
      this.$repository.semesters
        .courseSemesterUpdate(this.selectedSemester.id, this.selectedCourse.id, { ...this.form })
        .then(() => {
          this.$message.success({
            message: `Se ha actualizado la materia con éxito`,
            posdataon: `top-right`
          })
          this.fetchPensum({ id: this.$route.params.id, query: { with: 'periods,specialization,semesters.enabledCourses' } })
        })
      this.finalEvaluationsModal = false
    }
  },
  mounted() {
    this.fetchPensum({ id: this.$route.params.id, query: { with: 'periods,specialization,semesters.enabledCourses' } });
  }
}
</script>
